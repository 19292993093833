import './App.css';

function App() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <h1 style={{ fontSize: '2rem' }}>This page is under development. Please check back later!</h1>
    </div>
  );
}

export default App;
